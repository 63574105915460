import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { getUnreadMessageCount } from "@/api/system";

const useUserStore = defineStore("user", {
  state: () => ({
    token: getToken(),
    id: "",
    name: "",
    phone: "",
    promote: "",
    email: "",
    commissionRate: "",
    assign: "",
    money: "",
    avatar: "",
    unreadCount: 0,
    roles: [],
    permissions: [],
  }),
  actions: {
    // 登录
    login(userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((res) => {
            setToken(res.token);
            this.token = res.token;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const user = res.data;

            if (user.roles && user.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              this.roles = user.roles;
              this.permissions = user.permissions;
            } else {
              this.roles = ["ROLE_DEFAULT"];
            }
            this.id = user.user.id;
            this.name = user.user.username;
            this.commissionRate = user.user.commissionRate;
            this.assign = user.user.assign;
            this.money = user.user.money;
            this.phone = user.user.userPhone;
            this.email = user.user.email;
            this.promote = user.user.promote;
            getUnreadMessageCount().then((response) => {
              this.unreadCount = response.count;
            });
            resolve(user);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出系统
    logOut() {
      return new Promise((resolve, reject) => {
        logout(this.token)
          .then(() => { })
          .catch((error) => { })
          .finally(() => {
            this.token = "";
            this.roles = [];
            this.permissions = [];
            removeToken();
            resolve();
          });
      });
    },

    // 获取用户未读消息数量
    getUnreadCount() {
      return new Promise((resolve, reject) => {
        getUnreadMessageCount()
          .then((response) => {
            this.unreadCount = response.count;
            resolve(response.count);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default useUserStore;
