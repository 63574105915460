import Cookies from 'js-cookie'

const TokenKey = 'mgmt'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setSecurityToken(ossToken, ossKey, time) {
  return Cookies.set(ossToken, ossKey, { expires: time });
}
export function getSecurityToken(ossToken) {
  return Cookies.get(ossToken);
}
export function removeSecurityToken(ossToken) {
  return Cookies.remove(ossToken);
}
